<template>
  <div>
    <b-form-group>
      <b-form-input
        v-b-toggle.collapse-1
        v-model="search_txt"
        :disabled="isViewOnly"
        ref="search_input"
        @keyup.enter="selectIcon()"
        @click="checkCursorPosition"
        @keyup="checkCursorPosition"
        @input="clickTxt()"
        :class="
          visible
            ? 'search-icon-input collapsed-icons d-inline-block '
            : 'search-icon-input d-inline-block '
        "
        :aria-expanded="visible ? 'true' : 'false'"
        aria-controls="collapse-4"
      ></b-form-input>
      <!-- <b-input-group-append
          class="input-prefix-img search-clear curserPointer"
        >
          <feather-icon icon="XIcon" @click="clearTxt" size="20" />
        </b-input-group-append> -->

      <div v-if="visible" ref="divRef" class="search-icon-modal mb-1">
        <b-card class="search-card-protocol">
          <span
            v-for="(item, index) in items"
            :key="index"
            @click="selectIcon(item.property, true)"
            class="searchable-item"
          >
            <span
              ><h4>{{ item.name }}</h4>
              <h6>({{ item.property }})</h6></span
            >
          </span>
        </b-card>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import {
  BCard,
  BCollapse,
  BFormInput,
  BCol,
  VBToggle,
  BInputGroup,
  BFormGroup,
  BInputGroupAppend
} from "bootstrap-vue";
import { evaluate } from "mathjs";
// Note: Vue automatically prefixes the directive name with 'v-'

export default {
  name: "search",
  components: {
    BCard,
    BCollapse,
    BFormInput,
    BCol,
    VBToggle,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend
  },

  data() {
    return {
      test: "",
      visible: false,
      search_txt: "",
      items: [],
      filter: {},
      searchKey: "",
      preview: "",
      sensorsList: [],
      cursorPosition: 0,
      bracketType: "",
      bracketValue: ""
    };
  },
  directives: {
    "b-toggle": VBToggle
  },
  mounted() {
    document.addEventListener("click", this.handleOutsideClick);
    this.search_txt = this.value;
    // this.getSensorList();
    this.createGraph();
  },
  watch: {
    search_txt(val) {
      this.handleFocusSearch();

      const txt =
        val && (val.indexOf("{") !== -1 || val.indexOf("[") !== -1)
          ? true
          : false;
      if (this.search_txt && txt) {
        this.checkPattern();
      } else {
        this.$emit("input", this.search_txt);
        this.$emit("save", this.search_txt);
        this.$emit("valid", true);
      }
    },
    sensorsData: {
      handler(val) {
        this.createGraph();
      },
      deep: true
    },
    value(val) {
      this.search_txt = val;
      this.checkPattern();
    }
  },
  computed: {},
  beforeUnmount() {
    document.removeEventListener("click", this.handleOutsideClick);
  },
  created() {
    this.search_txt = this.$route.query.filter || "";
    this.handleFocusSearch();
  },
  props: [
    "telParameters",
    "value",
    "isViewOnly",
    "selectedSensor",
    "sensorsData",
    "sensorInfo",
    "sensorField"
  ],
  methods: {
    checkPattern() {
      const pattern =
        /^\s*(\{[a-zA-Z0-9_.]+\}|\[[a-zA-Z0-9_ \-]+#[a-zA-Z0-9_\-]+\]|\d+|convertToInt\(\{[a-zA-Z0-9_.]+\},\d+\))(\s*[\+\-\*\/%><=]+\s*(\{[a-zA-Z0-9_.]+\}|\[[a-zA-Z0-9_ \-]+#[a-zA-Z0-9_\-]+\]|\d+|convertToInt\(\{[a-zA-Z0-9_.]+\},\d+\)))*(?:\s*\?\s*(\{[a-zA-Z0-9_.]+\}|\[[a-zA-Z0-9_ \-]+#[a-zA-Z0-9_\-]+\]|\d+|convertToInt\(\{[a-zA-Z0-9_.]+\},\d+\))\s*:\s*(\{[a-zA-Z0-9_.]+\}|\[[a-zA-Z0-9_ \-]+#[a-zA-Z0-9_\-]+\]|\d+|convertToInt\(\{[a-zA-Z0-9_.]+\},\d+\)))?\s*$/;
      let input = this.search_txt;
      if (input) {
        input = input.replace(/const_(\d+)/g, "$1"); //Cleanup the const prefixes
        input = input.replace(/const(\d+)/g, "$1");
      }

      let sensorFieldPairs = this.parseInput(input);
      let invalidSensorFields = this.getInvalidSensorFields(
        sensorFieldPairs,
        this.sensorsData
      );
      const validPattern = pattern.test(input);

      if (
        !validPattern ||
        (invalidSensorFields && invalidSensorFields.length)
      ) {
        this.$emit("valid", false);
      } else {
        this.$emit("input", this.search_txt);
        this.$emit("save", this.search_txt);
        this.$emit("valid", true);
      }
    },
    parseInput(input) {
      let pattern = /\[([^\]]+?)#([^\]]+?)\]/g;
      let result = [];
      let match;

      while ((match = pattern.exec(input)) !== null) {
        result.push({
          sensor: match[1].trim(),
          field: match[2].trim()
        });
      }

      return result;
    },

    getInvalidSensorFields(sensorFieldPairs, sensorData) {
      return sensorFieldPairs.filter((pair) => {
        const sensor = sensorData.find((sensor) => sensor.name === pair.sensor);
        return !(sensor && sensor.parameter.includes(pair.field));
      });
    },
    getSensorList() {
      this.sensorsList = [];

      // Collect all sensors into an object for quick lookup
      const allSensors = {};
      this.sensorsData.forEach((item) => {
        allSensors[item.name.toLowerCase()] = item;
      });

      // Function to add a sensor to the list if it's not already present
      const addSensorToList = (item, field) => {
        const key = item.name.toLowerCase();
        this.sensorsList.push({
          name: `${item.name.trim()}#${field.trim()}`,
          key: key,
          property: `${item.name.trim()}#${field.trim()}`
        });
      };

      // Recursive function to process sensor references
      const processSensor = (item) => {
        const key = item.name.toLowerCase();
        for (const field in item.fields) {
          if (field && item.fields[field].source === "input") {
            const value = item.fields[field].value;
            if (value) {
              // Check if the value references another sensor
              const matches = value.match(/\[(\w+)#(\w+)\]/);
              if (matches) {
                const referencedSensor = matches[1].toLowerCase();
                const referencedField = matches[2];

                // Check if the referenced sensor is in the collected sensors
                if (allSensors[referencedSensor]) {
                  // Process the referenced sensor recursively
                  processSensor(allSensors[referencedSensor]);
                  // Add the referenced sensor's field to the list
                  addSensorToList(
                    allSensors[referencedSensor],
                    referencedField
                  );
                }
              }
            }

            // Add the current sensor's field to the list
            addSensorToList(item, field);
          }
        }
      };

      // Process each sensor
      this.sensorsData.forEach((item) => {
        if (item.name !== this.sensorInfo.name) {
          processSensor(item);
        }
      });
    },
    replaceRandomNumbers(inputString) {
      // Find all occurrences of '{' and '}'
      var openBraceIndices = [];
      var closeBraceIndices = [];

      for (var i = 0; i < inputString.length; i++) {
        if (inputString[i] === "{") {
          openBraceIndices.push(i);
        } else if (inputString[i] === "}") {
          closeBraceIndices.push(i);
        }
      }

      // Generate random numbers for each pair of braces
      var modifiedString = inputString;
      for (var j = 0; j < openBraceIndices.length; j++) {
        var content = inputString.substring(
          openBraceIndices[j] + 1,
          closeBraceIndices[j]
        );
        var randomNumber = Math.floor(Math.random() * 1000);
        modifiedString = modifiedString.replace(
          "{" + content + "}",
          "{" + randomNumber + "}"
        );
      }
      modifiedString = modifiedString.replace(/\{(.*?)\}/g, (match, key) => {
        return key;
      });
      return modifiedString;
    },
    handleFocusSearch() {
      if (this.$refs && this.$refs.search_input && window.innerWidth >= 768) {
        //   this.$refs.search_input.focus();
      }
    },
    handleOutsideClick(event) {
      const clickedElement = event.target;
      if (!this.$el.contains(clickedElement)) {
        this.visible = false;
      }
    },
    selectIcon(field, isSelect) {
      let param = field
        ? field
        : this.items && this.items.length
        ? this.items[0].property.trim()
        : null;
      if (!param) {
        this.preview = "";
        this.visible = false;
      }
      this.search_txt = this.replaceTextAfterLastBraceOrBracket(
        this.search_txt,
        param
      );
      // this.eval();
      this.visible = false;
      // this.createGraph();
    },
    eval() {
      let input = this.search_txt;
      input = input.replace(/const_(\d+)/g, "$1"); //Cleanup the const prefixes
      input = input.replace(/const(\d+)/g, "$1"); //Cleanup the const prefixes

      // input = input.replace(/\{(.*?)\}/g, (match, key) => {
      //   return key;
      // });

      try {
        // console.log(input);
        input = this.replaceRandomNumbers(input);
        if (input.includes("convertToInt")) {
          this.$emit("input", this.search_txt);
          this.$emit("save", this.search_txt);
          this.checkPattern();
        } else {
          this.preview = evaluate(input);
          this.$emit("input", this.search_txt);
          this.$emit("save", this.search_txt);
          this.$emit("valid", true);
        }
      } catch (err) {
        this.preview = input;
        this.$emit("valid", false);
      }
    },
    nameOfCustomEventToCall() {
      this.visible = false;
    },
    clickTxt() {
      try {
        if (this.search_txt) {
          let lastBraceIndex = this.search_txt.lastIndexOf("{");
          let lastCloseBraceIndex =
            this.search_txt.lastIndexOf("}") !== -1 &&
            this.search_txt.lastIndexOf("}") > lastBraceIndex
              ? this.search_txt.lastIndexOf("}")
              : undefined;

          let lastSquareBraceIndex = this.search_txt.lastIndexOf("[");
          let lastCloseSquareBraceIndex =
            this.search_txt.lastIndexOf("]") !== -1 &&
            this.search_txt.lastIndexOf("]") > lastSquareBraceIndex
              ? this.search_txt.lastIndexOf("]")
              : undefined;
          let isBrace = lastBraceIndex > lastSquareBraceIndex;
          if (isBrace) {
            let outputBrace =
              lastBraceIndex !== -1
                ? this.search_txt.substring(
                    lastBraceIndex + 1,
                    lastCloseBraceIndex
                  )
                : "";
            this.eval();
            const search = new RegExp(outputBrace, "i"); // prepare a regex object
            this.searchKey = "curly";
            let filterData = this.telParameters;
            if (this.sensorField && this.sensorField.data_type) {
              filterData = this.telParameters.filter(
                (e) =>
                  e &&
                  e.type &&
                  e.type.toLowerCase() ==
                    this.sensorField.data_type.toLowerCase()
              );
            }
            this.items = filterData.filter(
              (item) =>
                search.test(item.property) ||
                search.test(item.name) ||
                (search.test(item.name) && item.property)
            );
          } else {
            let outputSquareBrace =
              lastSquareBraceIndex !== -1
                ? this.search_txt.substring(
                    lastSquareBraceIndex + 1,
                    lastCloseSquareBraceIndex
                  )
                : "";
            if (!outputSquareBrace) {
              this.visible = false;
              return;
            }
            const search = new RegExp(outputSquareBrace, "i"); // prepare a regex object
            this.searchKey = "square";
            this.items = this.sensorsList.filter(
              (item) => search.test(item.property) || search.test(item.name)
            );
          }

          if (
            (lastCloseBraceIndex !== -1 && this.searchKey == "square") ||
            (lastCloseSquareBraceIndex !== -1 && this.searchKey == "curly")
          ) {
            if (!lastCloseBraceIndex || !lastCloseSquareBraceIndex) {
              this.visible = true;
            }
          }
        } else {
          this.preview = "";
          this.visible = false;
        }
      } catch (err) {}
    },
    replaceTextAfterLastBraceOrBracket(inputString, replacement) {
      try {
        // Find the last occurrence of '{' or '[' and everything after it
        let bracePattern = /(\{[^{}]*)$/;
        let bracketPattern = /(\[[^\[\]]*)$/;

        // Determine which pattern to use based on the last occurrence
        let braceIndex = inputString.lastIndexOf("{");
        let bracketIndex = inputString.lastIndexOf("[");

        if (braceIndex > bracketIndex) {
          // Replace the text after the last '{' with the replacement text
          return inputString.replace(bracePattern, "{" + replacement + "}");
        } else if (bracketIndex > braceIndex) {
          // Replace the text after the last '[' with the replacement text
          return inputString.replace(bracketPattern, "[" + replacement + "]");
        } else {
          // If neither '{' nor '[' are found, return the original string
          return inputString;
        }
      } catch (err) {}
    },
    clearTxt() {},

    createGraph() {
      try {
        const sensors = this.sensorsData || [];
        const newSensors = JSON.parse(JSON.stringify(sensors));
        const graph = {};

        for (let sensor of sensors) {
          let sensorIndex = newSensors.findIndex(
            (s) => this.trimName(s.name) === this.trimName(sensor.name)
          );
          const name = this.trimName(sensor.name);
          if (!(name in graph)) {
            graph[name] = [];
          }
          for (let key of Object.keys(sensor.fields)) {
            const field = sensor.fields[key];
            const regex = /\[(.*?)\]/g;
            let match;
            while ((match = regex.exec(field.value || "")) !== null) {
              const parameter = match[1].split("#");
              const sensorName = this.trimName(parameter[0]);
              graph[name].push(sensorName);
              const findParameterIndex = newSensors.findIndex(
                (sensor) => this.trimName(sensor.name) === sensorName
              );
              if (findParameterIndex > sensorIndex) {
                const temp = newSensors[findParameterIndex];
                newSensors[findParameterIndex] = newSensors[sensorIndex];
                newSensors[sensorIndex] = temp;
                sensorIndex = findParameterIndex;
              }
            }
          }
        }

        const key = this.trimName(this.sensorInfo.name);

        const allDependentKeys = this.getAllDependentKeys(graph, key);

        this.sensorsList = [];

        this.sensorsData.forEach((item) => {
          if (!allDependentKeys.includes(item.name) && item.name !== key) {
            for (let i = 0; i < Object.keys(item.fields).length; i++) {
              const field = Object.keys(item.fields)[i];
              if (item.fields[field] && item.fields[field].source === "input") {
                //    console.log(item);
                this.sensorsList.push({
                  name: `${item.name.trim()} ${item.parameter_label[i]}`,
                  key: item.name.trim(),
                  property: `${item.name.trim()}#${field.trim()}`
                });
              }
            }
          }
        });
        // }
        // return result;
      } catch (err) {}
    },
    trimName(name) {
      if (name) {
        return name.trim();
      } else {
        return name;
      }
    },
    getAllDependentKeys(data, target) {
      try {
        const dependencies = new Set();
        const reverseMap = new Map();

        function collectDependencies(key, value) {
          if (Array.isArray(value)) {
            if (value.includes(target)) {
              dependencies.add(key);
            }
            value.forEach((v) => {
              if (typeof v === "string") {
                if (!reverseMap.has(v)) {
                  reverseMap.set(v, []);
                }
                reverseMap.get(v).push(key);
              }
            });
          }
        }

        function traverse(obj) {
          const stack = [obj];
          while (stack.length > 0) {
            const current = stack.pop();
            for (const key in current) {
              if (current.hasOwnProperty(key)) {
                collectDependencies(key, current[key]);
                if (
                  typeof current[key] === "object" &&
                  !Array.isArray(current[key])
                ) {
                  stack.push(current[key]);
                }
              }
            }
          }
        }

        traverse(data);

        function collectAllDependencies(start) {
          const collected = new Set();
          const toProcess = [start];
          while (toProcess.length > 0) {
            const current = toProcess.pop();
            if (!collected.has(current)) {
              collected.add(current);
              const dependents = reverseMap.get(current) || [];
              toProcess.push(...dependents);
            }
          }
          return collected;
        }

        return [...collectAllDependencies(target)];
      } catch (err) {}
    },
    getCursorPosition() {
      const input = this.$refs.search_input.$refs.input;
      if (input) {
        this.cursorPosition = input.selectionStart;
      }
    },
    checkCursorPosition() {
      try {
        this.getCursorPosition();
        const result = this.getBracketType(this.cursorPosition);
        if (!result.type) {
          return;
        }
        this.searchKey = result.type;
        this.bracketValue = result.value;
        const search = new RegExp(result.value, "i"); // prepare a regex object
        if (this.searchKey === "square") {
          this.items = this.sensorsList.filter(
            (item) => search.test(item.property) || search.test(item.name)
          );
        } else if (this.searchKey === "curly") {
          let filterData = this.telParameters;
          if (this.sensorField && this.sensorField.data_type) {
            filterData = this.telParameters.filter(
              (e) =>
                e &&
                e.type &&
                e.type.toLowerCase() == this.sensorField.data_type.toLowerCase()
            );
          }
          this.items = filterData.filter(
            (item) =>
              search.test(item.property) ||
              search.test(item.name) ||
              (search.test(item.name) && item.property)
          );
        }

        this.visible = true;
      } catch (err) {}
    },
    getBracketType(position) {
      try {
        let squareBracketStart = this.search_txt.lastIndexOf("[", position - 1);
        let squareBracketEnd = this.search_txt.indexOf("]", position);
        let curlyBraceStart = this.search_txt.lastIndexOf("{", position - 1);
        let curlyBraceEnd = this.search_txt.indexOf("}", position);

        if (
          squareBracketStart !== -1 &&
          squareBracketEnd !== -1 &&
          squareBracketStart < position &&
          position <= squareBracketEnd
        ) {
          const value = this.search_txt.substring(
            squareBracketStart + 1,
            squareBracketEnd
          );
          return { type: "square", value: value };
        }

        if (
          curlyBraceStart !== -1 &&
          curlyBraceEnd !== -1 &&
          curlyBraceStart < position &&
          position <= curlyBraceEnd
        ) {
          const value = this.search_txt.substring(
            curlyBraceStart + 1,
            curlyBraceEnd
          );
          return { type: "curly", value: value };
        }

        return { type: "", value: "" };
      } catch (err) {}
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.search-filter {
  float: left;
  /*margin-right: 15px;*/
  margin-left: 0px;
  .form-group {
    margin-bottom: 0px !important;
  }
}
.search-card-protocol {
  background-color: $white;
  z-index: 9;
  margin-top: 14px;
  // max-width: 250px;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  .card-body {
    padding: 0rem;
    .searchable-item {
      float: left;
      width: 100%;
      padding: 0.7rem 0.8rem;
      border-bottom: 1px solid #ddd;
      &:hover {
        background-color: rgba(115, 103, 240, 0.12);
        color: var(--primary);
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
.dark-layout {
  .search-card-protocol {
    background-color: var(--dark46) !important ;
    color: var(--white);
  }
}
.search-icon-modal {
  position: absolute;
  z-index: 9;
  padding: $px_10;
  margin-top: -17px !important;
  min-width: $percent_100;
  min-height: 32vh;
  padding: 5px 0;
  width: 100%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin: 0;
}
.search-clear {
  padding: 6px 5px 0px 5px;
  border-radius: 0rem 0.357rem 0.357rem 0rem;
  svg {
    margin-top: 3px;
  }
}
input:focus + .input-prefix-img {
  border: 1px solid var(--primary);
}
</style>
